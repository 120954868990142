<template>
  <v-list-item :color="peer.me ? 'primary' : 'red'">
    <v-list-item-icon>
      <v-icon>mdi-account</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="peer.name"></v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        icon
        :disabled="!peer.me && mutedByPeer"
        :color="!peer.me && mutedByPeer ? 'gray' : 'primary'"
        @click="
          $emit('mute-update', peer.id, isMe ? !mutedByPeer : !peer.muted)
        "
      >
        <v-icon dark>{{
          peer.muted || mutedByPeer ? "mdi-microphone-off" : "mdi-microphone"
        }}</v-icon>
      </v-btn>
    </v-list-item-action>
    <audio ref="media" :muted="isMuted" style="display: none" />
  </v-list-item>
</template>

<script>
export default {
  props: ["peer", "isMe", "mutedByPeer"],
  watch: {
    isMuted(newValue) {
      this.$refs.media.muted = newValue;
    },
  },
  mounted() {
    this.$refs.media.srcObject = this.peer.stream;
    this.$refs.media.addEventListener("loadedmetadata", () => {
      this.$refs.media.play();
      if (this.isMuted) {
        this.$refs.media.muted = true;
      }
    });
  },
  computed: {
    isMuted() {
      return this.isMe || this.mutedByPeer || this.peer.muted;
    },
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
}
.me {
  transform: scale(-1, 1);
}
</style>
