<template>
  <v-app>
    <v-main dark>
      <v-dialog
        :value="true"
        :fullscreen="$vuetify.breakpoint.mobile"
        :max-width="$vuetify.breakpoint.mobile ? null : 600"
        persistent
      >
        <enter-form v-if="!me" @submit="onUserNameSubmit" />
        <chat-room v-else :me="me" :audio-device="audioDevice" />
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import EnterForm from "./EnterForm";
import ChatRoom from "./ChatRoom";

export default {
  name: "AppExperimental",
  components: {
    EnterForm,
    ChatRoom,
  },
  data() {
    return {
      me: null,
      audioDevice: null,
      members: [],
    };
  },
  methods: {
    onUserNameSubmit(value) {
      const { name, audioDevice } = value;
      this.audioDevice = audioDevice;
      this.me = {
        name,
      };
    },
  },
  computed: {},
};
</script>