<template>
  <v-card>
    <v-card-title>Представьтесь, пожалуйста:</v-card-title>
    <v-card-text>
      <v-text-field label="Ваше имя" v-model="name" hint="Минимум 4 символа" />
      <v-select
        :items="devices"
        v-model="selectedDevice"
        label="Выберите микрофон"
        item-text="label"
        return-object
        v-if="isAudioDeviceExists && !isBlankAudioDevice"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text :disabled="!isValid" @click="onSubmit">
        Войти
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const MIN_NAME_LENGTH = 4;
export default {
  name: "EnterForm",
  data() {
    return {
      name: "",
      selectedDevice: null,
      isAudioDeviceExists: true,
      isBlankAudioDevice: false,
      devices: [],
    };
  },
  created() {
    this.getConnectedDevices();
  },
  computed: {
    isValid() {
      if (!this.name) return false;
      const isNameValid = this.name.trim().length >= MIN_NAME_LENGTH;
      const isAudioValid =
        this.selectedDevice != null ||
        (this.isAudioDeviceExists && this.isBlankAudioDevice);
      return isNameValid && isAudioValid;
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit", {
        name: this.name,
        audioDevice: this.selectedDevice,
      });
    },
    async getConnectedDevices() {
      const kind = "audioinput";

      let devices = await navigator.mediaDevices.enumerateDevices();
      devices = devices.filter((device) => device.kind === kind);

      if (devices.length === 0) {
        this.isAudioDeviceExists = false;
      } else if (devices.length > 0) {
        this.isAudioDeviceExists = true;
        this.isBlankAudioDevice = !devices[0].deviceId;
        if (!this.isBlankAudioDevice) {
          this.devices = devices;
          this.selectedDevice = this.devices[0];
        }
      }
    },
  },
};
</script>